<script>
import Layout from "../../layouts/franchise";
import { VclList } from "vue-content-loading";
import { api } from "@/state/services";

export default {
  locales: {
    pt: {
      'Inventory': 'Estoque',
      'Products': 'Produtos',
    },
    es: {
      'Inventory': 'Estoque',
      'Products': 'Produtos',
    }
  },
  components: {
    Layout,
    VclList
  },
  data() {
    return {
      products: null
    };
  },
  methods: {
    getInventory() {
      api
        .get('franchise/inventory')
        .then((response) => {
          if (response.data.status == 'success') {
            this.products = response.data.list
          } else {
            this.products = []
          }
        })
        .catch(error => {
          if (error) {
            this.products = []
          }
        })
    }
  },
  mounted() {
    this.getInventory()
  }
};
</script>

<template>
  <Layout>
    <div class="row">
      <div class="col-12">
        <div class="page-title-box d-flex justify-content-between">
          <h4 class="mb-0 font-size-18">{{ t('Inventory') }} › {{ t('Products') }}</h4>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-lg-12">
        <div class="card">
          <div class="card-body">
            <div class="table-responsive">
              <vcl-list v-if="!products" class="col-lg-6"></vcl-list>
              <template v-else-if="products.length == 0">
                Nenhum registro encontrado.
              </template>
              <table v-else class="table table-nowrap">
                <thead class="thead-light">
                  <tr>
                    <th style="width: 1%;"></th>
                    <th class="text-center" style="width: 1%;">Total</th>
                    <th class="text-center" style="width: 1%;">Disponível</th>
                    <th class="text-center" style="width: 1%;">Reservado</th>
                    <th>Nome</th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="(pro,index) in products" :key="index">
                    <td class="py-1">
                      <div class="pt-1">
                        <span class="rounded-circle" :style="'float:left; background:' + pro.color +'; width:13px; height:13px;'"></span>
                      </div>
                    </td>
                    <td class="text-center py-1">{{ pro.total }}</td>
                    <td class="text-center py-1">{{ pro.available }}</td>
                    <td class="text-center py-1">{{ pro.reserved }}</td>
                    <td class="py-1">{{ pro.name }}</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  </Layout>
</template>
